import React from "react"
import { StaticImage } from "gatsby-plugin-image";


export default function SectionEngagement() {
  return (
    <div className="bg-art-beige">
      <div className="mx-auto max-w-7xl pt-24 sm:px-2 sm:pt-32 lg:px-4">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <div className="max-w-3xl">
            <h2 className="text-4xl font-bold tracking-tight text-art-marron-fonce sm:text-6xl">
              Nos engagements
            </h2>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">

            <div className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <StaticImage
                  className="h-16 w-16"
                  src="../../images/icon-shipping-simple.svg"
                  alt=""
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                <h3 className="text-lg font-normal text-art-marron-fonce">Savoir-faire depuis plus de 25 ans</h3>
              </div>
            </div>
            <div className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <StaticImage
                  className="h-16 w-16"
                  src="../../images/icon-warranty-simple.svg"
                  alt=""
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                <h3 className="text-lg font-normal text-art-marron-fonce">Visite commerciale et devis gratuits</h3>
              </div>
            </div>
            <div className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <StaticImage
                  className="h-16 w-16"
                  src="../../images/icon-exchange-simple.svg"
                  alt=""
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                <h3 className="text-lg font-normal text-art-marron-fonce">Visite commerciale et devis gratuits</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
